<template>
  <b-card class="w-full d-flex justify-content-center align-items-center py-4">
    <lottie-animation
      ref="anim"
      :animation-data="require('@/assets/animations/data-center.json')"
      :loop="true"
      :auto-play="true"
      :speed="1"
      class="data-center"
    />
    <b-button
      href="https://developer.curfox.com"
      target="_blank"
      type="submit"
      class="w-100 mt-3 custom-button-color"
    >
      Visit Developer Portal
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
} from 'bootstrap-vue'
import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'ApiOrderCreate',
  components: {
    BCard,
    BButton,
    LottieAnimation,
  },
}
</script>
<style lang="scss" scoped>
@import '../../../@core/scss/button-color.scss';
.data-center {
  width: 24vw;
  height: 24vw;
}

@media screen and (max-width: 1268px) {
  .data-center {
    width: 40vw;
    height: 40vw;
  }
}

@media screen and (max-width: 768px) {
  .data-center {
    width: 60vw;
    height: 60vw;
  }
}
</style>
