<template>
  <b-tabs ref="orderCreateTabBar">
    <b-tab
      lazy
      title="Single Upload"
    >
      <single-order-create
        :order-settings="orderSettings"
        :waybill-placeholder="waybillPlaceholder"
        :waybill-pattern="waybillPattern"
        :pattern-title="patternTitle"
        @set-placeholder="fetchAssignedWaybillSeries"
      />
    </b-tab>
    <b-tab
      lazy
      title="Bulk Upload"
    >
      <bulk-order-create
        :order-settings="orderSettings"
        :waybill-placeholder="waybillPlaceholder"
        :waybill-pattern="waybillPattern"
        :pattern-title="patternTitle"
        @set-placeholder="fetchAssignedWaybillSeries"
      />
    </b-tab>
    <b-tab
      lazy
      title="Excel Upload"
    >
      <advance-excel-order-create
        :order-settings="orderSettings"
        :waybill-placeholder="waybillPlaceholder"
        :waybill-pattern="waybillPattern"
        :pattern-title="patternTitle"
      />
    </b-tab>
    <b-tab
      lazy
      title="API Upload"
    >
      <API-order-create />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'

import SingleOrderCreate from '@/views/order-section/create/SingleOrderCreate.vue'
import BulkOrderCreate from '@/views/order-section/create/BulkOrderCreate.vue'
import AdvanceExcelOrderCreate from '@/views/order-section/create/AdvanceExcelOrderCreate.vue'
import APIOrderCreate from '@/views/order-section/create/APIOrderCreate.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const OrderSettingsRepository = RepositoryFactory.get('orderSetting')
const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

export default {
  components: {
    BTabs,
    BTab,
    SingleOrderCreate,
    BulkOrderCreate,
    APIOrderCreate,
    AdvanceExcelOrderCreate,
  },
  data() {
    return {
      waybillSeriesPreview: [],
      orderSettings: {},
      manualWaybillPrefix: '',
      manualWaybillPrefix2: '',
      manualWaybillSuffix: '',
      waybillDigitCount: 0,
    }
  },
  async mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('orderCreateTabBar', 'orderCreateTabIndex')
    await this.readOrderSetting()
  },
  methods: {
    async fetchAssignedWaybillSeries() {
      this.loading = true
      try {
        const { data } = (await waybillSettingsRepository.fetchAssignedWaybillSeries())
        const assignedWaybillSeries = data.data
        const manualWaybillSeries = assignedWaybillSeries.filter(n => n.waybill_series.is_auto === false)
        this.waybillSeriesPreview = manualWaybillSeries.map(n => n.waybill_series.preview)
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    async readOrderSetting() {
      this.loading = true
      try {
        this.orderSettings = localStorage.getItem('order_setting') ? JSON.parse(localStorage.getItem('order_setting')) : (await OrderSettingsRepository.getOrderSettingsResource()).data.data
        this.manualWaybillPrefix = this.orderSettings.waybill_setting.manual_waybill_prefix
        this.manualWaybillPrefix2 = this.orderSettings.waybill_setting.manual_waybill_prefix_2
        this.waybillDigitCount = this.orderSettings.waybill_setting.digit_count
        if (this.orderSettings.waybill_setting.suffix_enabled) this.manualWaybillSuffix = `${this.orderSettings.waybill_setting.manual_waybill_suffix}`
        if (!localStorage.getItem('order_setting')) localStorage.setItem('order_setting', JSON.stringify(this.orderSettings))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    waybillPlaceholder(autoWaybill) {
      let placeholder
      if (!autoWaybill) {
        placeholder = 'XXxxxxxx'
        if (this.waybillSeriesPreview.length !== 0) {
          placeholder = this.waybillSeriesPreview.join('/')
        }
      } else {
        placeholder = 'Auto Generate'
      }
      return placeholder
    },
    waybillPattern(autoWaybill) {
      let pattern = ''
      if (!autoWaybill) {
        if (this.manualWaybillPrefix && this.waybillDigitCount) {
          pattern = `${this.manualWaybillPrefix}[0-9]{${this.waybillDigitCount}}${this.manualWaybillSuffix}`
          if (this.manualWaybillPrefix2) {
            pattern = `${pattern}|${this.manualWaybillPrefix2}[0-9]{${this.waybillDigitCount}}${this.manualWaybillSuffix}`
          }
        }
      }
      return pattern || null
    },
    patternTitle(autoWaybill) {
      let title = ''
      if (!autoWaybill) {
        if (this.manualWaybillPrefix && this.waybillDigitCount) {
          title = `The waybill number must start with ${this.manualWaybillPrefix} and contain ${this.waybillDigitCount} trailing ${this.orderSettings.waybill_setting.digit_count === 1 ? 'digit' : 'digits'}`
          if (this.manualWaybillPrefix2) {
            title = `The waybill number must start with ${this.manualWaybillPrefix} or ${this.manualWaybillPrefix2} and contain ${this.waybillDigitCount} trailing ${this.orderSettings.waybill_setting.digit_count === 1 ? 'digit' : 'digits'}`
          }
        }
        if (this.manualWaybillSuffix) title = `${title}. Additionally the waybill must end with ${this.manualWaybillSuffix}`
      }
      return title || null
    },
  },
}
</script>
